<template>
  <div class="show" :style="`background: ${$AppData.config.appPageInfoList.filter(item => item.code === 'ShowFragment')[0]?.backgroundColor || '#f6f8fb'} url(${$AppData.config.backgroundImgUrl}) 0 0/100% auto no-repeat;`">
    <p class="page-title">{{$AppData.config.appPageInfoList[2].show}}</p>
    <div class="show-list">
      <template v-for="item in bannerShowList" :key="item.id">
        <van-image v-if="item.type === 0" class="show-image" :src="item.bannerImgUrl"  @click="goToArticle(item)" />
        <a v-else-if="item.type === 1" :href="item.content" target="_blank">
          <van-image class="show-image" :src="item.bannerImgUrl" />
        </a>
        <van-image v-else-if="item.type === 2" class="show-image" :src="item.bannerImgUrl" @click="goToPage({code: item.content, name: item.name })" />
        <template v-else-if="item.type === 4">
          <van-image v-if="jsBrige" class="show-image" :src="item.bannerImgUrl" @click="goToIHash" />
          <a v-else href="/farmer-api/egg/login" target="_blank">
            <van-image class="show-image" :src="item.bannerImgUrl" />
          </a>
        </template>
        <van-image v-else class="show-image" :src="item.bannerImgUrl" />
      </template>
    </div>
    <articlepopup :show="showArticle" :article="article" @hide="showArticle = false" />
  </div>
</template>

<script>
import { ref } from 'vue';
import articlepopup from '@/components/Article';
import { queryBannerShowList, queryConfigFunction } from "@/api";


export default {
  components: {
    articlepopup,
  },
  
  setup() {
    const loading = ref(false);
    return {
      loading,
    };
  },
    
  data() {
    return {
      bannerShowList: [],
      mode: 0,
      article: {},
      showArticle: false,
      jsBrige: window.jsBridge,
    };
  },
  
  mounted() {
    this.fetchBannerShowList();
  },

  methods: {
    fetchBannerShowList() {
      queryBannerShowList().then(res => {
        this.bannerShowList = res.data;
      });
    },
    goToArticle(item) {
      if(this.$AppData.config.themeType) {
        this.showArticle = true;
        this.article = item;
      } else {
        localStorage.setItem('article', JSON.stringify(item));
        this.$router.push('article');
      }
    },
    goToIHash() {
      if (window.jsBridge) {
        window.jsBridge.invokeNative('openWebView', `${window.location.origin}/farmer-api/egg/login`, '');
      }
    },
    goToPage(item) {
      switch (item.code){
        case 'MULTI_LEVEL_CODE':
          this.dialogTitle = item.name;
          this.showDialog = true;
          break;
        case "NUMBER_OF_USER_REGISTRATIONS"://注册人数
        case "INCOME"://收益
        case "REBATE"://返佣
        case "VOLUME"://成交量
        case "PURCHASE_AMOUNT"://购买量
        case "SUCCESS_RATE"://成功率
        case "NUMBER_OF_ORDER"://订单数
        case "BUY_COINS_QUICKLY_VOLUME"://急速跑量
        case "BUY_COINS_QUICKLY_INCOME"://急速收益
          localStorage.setItem('historyCode', item.code);
          localStorage.setItem('pageName', item.name);
          this.$router.push('/history');
          break;
        default:
          const page = this.$AppData.pageMapping[item.code];
          if(!page) {
            return false;
          }
          localStorage.setItem('pageName', item.name);
          this.$router.push(page);
          break;
      }
    },
  },
};
</script>

<style lang="less">
.show {
  min-height: 100vh;
  padding-bottom: 50px;
  box-sizing: border-box;
  .page-title {
    padding: 10px 16px;
    line-height: 60px;
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    text-align: center;
  }
  .show-list {
    padding: 16px;
    .show-image {
      width: 100%;
      margin-bottom: 16px;
      img { border-radius: 9px; }
    }
  }
}
</style>
